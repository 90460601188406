.card-container {
  border-radius: 20px;
}

.card-item-max-h {
  padding: 0;
  max-height: 500px;
}

.card-item-n-max-h {
  padding: 0;
  max-height: none;
}

.card-item {
  padding: 0;
  max-height: 500px;
}