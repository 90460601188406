.report-layout {
  $boxShadowColor: rgba(0, 0, 0, 0.25);
  $backgroundColor: #4dc0b2;
  $hoverColor: #09b080;
  $borderSolidColor: #828282;
  $textColorError: #fa5c7c;

  .custom-btn {
    padding: 6px 25px !important;
    background-color: #4dc0b2 !important;
    border-radius: 10px !important;
    box-shadow: 0px 4px 4px $boxShadowColor;
    border: none !important;
  }

  .react-datepicker__month-text:hover {
    &:hover {
      background-color: #727cf5;
      color: white;
    }
  }
  .react-datepicker__year-text {
    &:hover {
      background-color: #727cf5;
      color: white;
    }
  }
  .error {
    font-size: 12px;
    color: $textColorError;
    margin: 0;
  }
}
