.accomm-card-print {
  max-width: 1280px;
  margin: auto;
  padding: 50px;
  border-bottom: 1px dashed !important;
  &:last-child {
      border-bottom: none !important;
  }
  .card-header {
      border: none;
  }
  .personal-logo {
      margin: auto;
      width: 300px;
      border: 2px solid;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
  }
  .no-border-body {
      td {
          border-bottom: none;
          border-top: none;
          padding: 5px;
      }
  }
  .table {
      margin: 25px 0px;
      width: 100%;
      text-align: center;
      th, td {
          vertical-align: middle;
          color: black;
          border: 1px solid;
          padding: 5px;
      }
  }
  .border-bottom-2 {
      border-bottom: 2px solid;
  }
}

.form-action {
  button {
      border: none;
      width: 155px;
      height: 54px;
      margin: 0 25px;
  }
  .btn-save {
      background-color: #4DC0B2;
  }
  .btn-cancel {
      background-color: #C4C4C4;
  }
}