// Button shadow

@mixin button-shadow($color) {
  box-shadow: $btn-box-shadow rgba($color, 0.5);
}

.cursor-pointer {
  cursor: pointer;
}

.text-weight-light {
  font-weight: 300;
}