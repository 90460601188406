//
// buttons.scss
//

.btn {
  .mdi {
    &:before {
      line-height: initial;
    }
  }
}

.btn-rounded {
  border-radius: 2em;
}

// Button Shadows
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-shadow($value);
  }
}

.button-hover {
  transition: all 0.2s linear;
  &:hover {
    opacity: 0.7; 
  }
}
.btn-common {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  @extend .button-hover;
}

.btn-edit-banquet-plan {
  background-color: #4DC0B2;
  border: none;
  border-radius: 10px;
  &:hover {
    background-color: #09b080;
    border-color: #08a679;
  }
}

.primary-button {
  @extend .btn-common;
  background-color: #4DC0B2;
  color: white;
  width: 114px;
  &:hover {
    color: white;
  }
}

.secondary-button {
  @extend .btn-common;
  background-color: #C4C4C4;
  color: white;
  width: 114px;
  &:hover {
    color: white;
  }
}